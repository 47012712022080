import React, { useEffect, useState } from "react";
import "./Head.css";
import btn1 from "../Image/shop_black_24dp 1.png";
import btn2 from "../Image/featured_video_black_24dp 1.png";
import Item from "./Item";
const Head = () => {
	const [data, setData] = useState([]);

	useEffect(() => {
		fetch("../../../data.json")
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	return (
		<div className="header  mt-5 mb-5 p-3" id="about">
			<div className="container mb-5">
				<div className="col-10 col-lg-12 mx-auto">
					<div className="head-part1 mb-5">
						<div className="title mb-2" data-aos="fade-up">
							Boiling Proxies
						</div>
						<div className="sub-title mb-2" data-aos="fade-up">
							Unbanned | Fast | Efficient | All-in-One Platform |
						</div>
						<div className="title-deatils  mb-5" data-aos="fade-up">
							Be a part of the world’s most efficient proxy network. Be assured
							your data is safe.
						</div>
						{/* <div
							className="button-part d-flex flex-sm-row  flex-column mb-5 mt-3"
							data-aos="fade-up"
						>
							<button
								className="button align-items-center justify-content-center d-flex me-lg-3 mb-lg-0 mb-5 text-center"
								data-aos="fade-up"
							>
								<div className="img me-1">
									<img src={btn1} width="20px" alt="" />
								</div>
								<div>Purchase</div>
							</button>
							<button
								className="button align-items-center justify-content-center mb-lg-0 mb-5 d-flex mx-sm-3"
								data-aos="fade-up"
							>
								<div className="img me-1">
									<img src={btn2} width="20px" alt="" />
								</div>
								<div>Watch Intro</div>
							</button>
						</div> */}
					</div>
					<div className="fetch-part mt-5 mb-5">
						<div className="grid-part text-lg-start mx-auto">
							{data.map((item) => (
								<Item itempart={item} key={item.id} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Head;
