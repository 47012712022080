import React from "react";

const Item = ({ itempart }) => {
	const { title, description } = itempart;
	return (
		<div className="item-part mx-auto mt-5">
			<div className="d-flex align-items-end" data-aos="zoom-in">
				<div className=" mb-5 red-box"></div>
				<div className="right-text mx-3">
					<div className="right-text-title mb-2">{title}</div>
					<div className="right-text-details">{description}</div>
				</div>
			</div>
		</div>
	);
};

export default Item;
