import React from "react";
import "./Tos.css";
const Tos = () => {
	return (
		<div className="tos mt-5 mb-5" id="tos">
			<div className="container">
				<div className="col-10 col-lg-12 mx-auto">
					<div className="tos mb-3" data-aos="fade-up">
						Terms and conditions
					</div>
					<div className="tos-deatils mb-5" data-aos="fade-up">
						By accessing or using our Services it will mean that you are
						agreeing to be bound to all the terms, conditions, rules and
						policies. Please read all of these very carefully. If you disagree
						with any of the terms, conditions, rules or policies, then you may
						not have access to our Services/Products. <br />
						<br />
						The Terms and Conditions (‘’Terms’’, ‘’Terms and Conditions”) are
						defined for the use of{" "}
						<a
							href="https://www.boilingproxies.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://www.boilingproxies.com
						</a>
						(‘’Website’’). <br />
						<br />
						The Terms are applied to all visitors/users and others who are
						accessing our website and using our Services. <br />
						<br />
						By accessing and using our Services you agree to be bound by these
						Terms and have read the Terms. <br />
						<br />
						If you disagree with any of the terms, conditions, rules or
						policies, then you may not have access to our Services/Products.
					</div>
				</div>
			</div>
		</div>
	);
};

export default Tos;
