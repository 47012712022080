import React, { useEffect, useState } from "react";

import "./Feature.css";
import SFeature from "./SFeature";
const Feature = () => {
	const [data, setData] = useState([]);

	useEffect(() => {
		fetch("../../../feature.json")
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	return (
		<div className="feature" id="Features">
			<div className="container">
				<div className="col-10 col-lg-12 mx-auto">
					<div className="feature mb-5" data-aos="fade-up">
						Why you should choose for Boiling Proxies:
					</div>
					<div className="feature-grid mb-5">
						{data.map((feature) => (
							<SFeature featureCard={feature} key={feature.id} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feature;
