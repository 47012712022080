import React, { useEffect, useState } from "react";
import PlanItem from "./PlanItem";
import "./Plans.css";
const Plans = () => {
	const [data, setData] = useState([]);

	useEffect(() => {
		fetch("../../../Plan.json")
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	return (
		<div className="plans">
			<div className="container">
				<div className="row">
					<div className="col-10 col-lg-12 mx-auto">
						<div className="products mb-4" data-aos="fade-up">
							Products
						</div>
						<span className="secret-revel  mb-4" data-aos="fade-up">
							Secret Residential
						</span>
						<div
							className="secret-plan mt-5 mb-3"
							data-aos="fade-up"
							id="Pricing"
						>
							Secret Plans
						</div>
						<div className="secret-deatils mb-5" data-aos="fade-up">
							Our Secret Residential Proxies are working on every website and is
							also perfect for <br /> sneaker botting.
						</div>
						<div className="plan-grid mt-5 mb-5">
							{data.map((item) => (
								<PlanItem planItem={item} key={item.id} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Plans;
