import React from "react";

const SFeature = ({ featureCard }) => {
	const { photo, name, driscription } = featureCard;
	return (
		<div className="feature-card p-3 mb-md-0 mb-5" data-aos="zoom-in">
			<div className="feature-img mx-auto mt-3 mb-2">
				<img src={photo} alt="" width="30px" className="img-fluid" />
			</div>
			<div className="feature-title mb-2">{name}</div>
			<div className="feature-deatils mb-5">{driscription}</div>
		</div>
	);
};

export default SFeature;
