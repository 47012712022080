import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../Image/Boiling Proxies logo transparant 1.png";
import twitter from "../Image/twitter.svg";
import "./Navbar.css";

const Navbar = () => {
	const [color, setColor] = useState(false);
	const changeColor = () => {
		if (window.scrollY >= 110) {
			setColor(true);
		} else {
			setColor(false);
		}
	};
	window.addEventListener("scroll", changeColor);

	return (
		<nav
			className={
				color
					? "navbar navbar-expand-lg sticky-top scroll box-shadow p-3"
					: "navbar navbar-expand-lg  sticky-top withoutScroll p-3"
			}
		>
			<div className="container">
				<NavLink className="navbar-brand" to="/">
					<img src={logo} alt="" width="100px" className="logo img-fluid" />
				</NavLink>
				<button
					className="navbar-toggler collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="toggler-icon top-bar"></span>
					<span className="toggler-icon middle-bar"></span>
					<span className="toggler-icon bottom-bar"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav d-flex align-items-lg-center mx-lg-0 ms-lg-auto mb-lg-0 mb-3">
						<li className="nav-item mx-3 mb-lg-0 mb-3 mt-lg-0 mt-3">
							<Nav.Link as={HashLink} className="nav-link" to="/#about">
								Home
							</Nav.Link>
						</li>
						<li className="nav-item mx-3 mb-lg-0 mb-3">
							<Nav.Link as={HashLink} className="nav-link" to="/#Pricing">
								Pricing
							</Nav.Link>
						</li>

						<li className="nav-item mx-3 mb-lg-0 mb-3">
							<a
								className="nav-link"
								href="https://twitter.com/BoilingProxies"
								target="_blank"
							>
								<img src={twitter} width="50px" alt="" className="img-fluid" />
							</a>
						</li>
					</ul>
					<a
						href="https://dashboard.boilingproxies.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						<button type="button" className="button mb-lg-0  mb-5">
							Dashboard
						</button>
					</a>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
