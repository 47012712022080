import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import footer from "../Image/Boiling Proxies logo transparant 1.png";
import { HashLink } from "react-router-hash-link";
import "./Footer.css";
const Footer = () => {
	return (
		<>
			<div className="footer" id="footer">
				<div className="container mt-5 mb-5">
					<div className="footer-grid" data-aos="fade-up">
						<div className="footer-grid-item-img mx-auto">
							<div className="felx-part d-flex justify-content-between align-items-center flex-column">
								<div className="footer-img mb-4">
									<Nav.Link as={HashLink} className="link" to="/#footer">
										<img
											src={footer}
											className="img-fluid"
											width="100px"
											alt=""
										/>
									</Nav.Link>
								</div>
							</div>
						</div>
						<div className="footer-grid-item mx-auto mx-lg-0 mb-4 text-center mb-lg-0  text-lg-start">
							<div className="menu mb-3 ">Menu</div>
							<div className="menu-item ">
								<li className="mb-2">
									<Nav.Link as={HashLink} className="link" to="/#about">
										Home
									</Nav.Link>
								</li>
								<li className="mb-2">
									<Nav.Link as={HashLink} className="link" to="/#Pricing">
										Pricing
									</Nav.Link>
								</li>

								<li className="mb-2">
									<a href="http://" target="_blank" rel="noopener noreferrer">
										Contacts
									</a>
								</li>
							</div>
						</div>
						{/* services */}
						<div className="footer-grid-item mx-auto mx-lg-0 mb-4 text-center mb-lg-0 mx-auto text-lg-start">
							<div className="legal mb-3">Services</div>
							<div className="legal-item ">
								<li className="mb-2">
									<Nav.Link as={HashLink} className="link" to="/#about">
										Home
									</Nav.Link>
								</li>
								<li className="mb-2">
									<Nav.Link as={HashLink} className="link" to="/#Pricing">
										Pricing
									</Nav.Link>
								</li>

								<li className="mb-2">
									<a href="" target="_blank" rel="noopener noreferrer">
										Contacts
									</a>
								</li>
							</div>
						</div>
						{/* Terms & Conditions  */}
						<div className="footer-grid-item text-center mx-auto mx-lg-0 mb-4 mb-lg-0 text-lg-start">
							<div className="legal mb-3">Terms & Conditions</div>
							<div className="legal-item ">
								<li className="mb-2">
									<Nav.Link as={HashLink} className="link" to="/tos#tos">
										Terms
									</Nav.Link>
								</li>
								<li className="mb-2">
									<Nav.Link
										as={HashLink}
										className="link"
										to="/services#services"
									>
										Services
									</Nav.Link>
								</li>
								<li className="mb-2">
									<Nav.Link
										as={HashLink}
										className="link"
										to="/purchases#purchase"
									>
										Purchases
									</Nav.Link>
								</li>
							</div>
						</div>
						{/* Social */}
						<div className="footer-grid-item mx-auto text-center mx-lg-0 mb-4 mb-lg-0 mx-auto  text-lg-start">
							<div className="legal mb-3">Social</div>
							<div className="legal-item ">
								<li className="mb-2">
									<a
										href="https://twitter.com/BoilingProxies"
										target="_blank"
										rel="noopener noreferrer"
									>
										Twitter
									</a>
								</li>
								<li className="mb-2">
									<a
										href="https://www.facebook.com/BoilingProxies"
										target="_blank"
										rel="noopener noreferrer"
									>
										Facebook
									</a>
								</li>
								<li className="mb-2">
									<a
										href="https://www.instagram.com/boilingproxies/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Instagram
									</a>
								</li>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="copy-right p-3 text-center">
					Operated by Boiling Proxies.
				</div>
			</div>
		</>
	);
};

export default Footer;
