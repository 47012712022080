import React from "react";

const PlanItem = ({ planItem }) => {
	const { price, list1, list2, list3, list4, list5, gb } = planItem;
	return (
		<div className="plan-grid-item mb-md-0 mb-5" data-aos="fade-up">
			<div className="red-color p-4">
				<div className="d-flex mt-3 mb-3 align-items-center">
					<div className="left-plan"></div>
					<div className="secret mx-3">Secret</div>
				</div>
				<div className="dollar mb-3">{price}</div>
			</div>
			<div className="bottom-part-plan p-4">
				<li className="plan-list mb-2">{list1}</li>
				<li className="plan-list mb-2">{list2}</li>
				<li className="plan-list mb-2">{list3}</li>
				<li className="plan-list mb-2">{list4}</li>
				<li className="plan-list mb-3">{list5}</li>
				<div className="button-black mb-2 p-3">{gb}</div>
				<a
					href="https://dashboard.boilingproxies.com"
					target="_blank"
					className="purchase-link"
					rel="noopener noreferrer"
				>
					<div className="button-red text-center mb-2 p-3">Purchase</div>
				</a>
			</div>
		</div>
	);
};

export default PlanItem;
