import React from "react";
import "./Purchase.css";
const Purchase = () => {
	return (
		<div className="purchase mt-5 mb-5" id="purchase">
			<div className="container">
				<div className="row">
					<div className="col-10 col-lg-12 mx-auto">
						<div className="purchases mb-3" data-aos="fade-up">
							Purchases
						</div>
						<div className="purchases-deatils  mb-5" data-aos="fade-up">
							If you want to purchase our product/Service, you can do that by
							placing an order on our Website. When purchasing or placing an
							order, you may be asked for Personal Information such as your full
							name, e-mail address, residential address, telephone number,
							billing address, shipping information and your credit card number
							with the expiration date and security code. <br />
							<br />
							If you are not providing us the correct information for an order,
							then it may be possible that your order can not be processed.{" "}
							<br />
							<br />
							Purchases are depending on the availability of the Services, which
							can be limited. <br />
							<br />
							All purchases of any kind of order from our Website are not
							refundable. When purchasing, you are hereby agreeing to the fact
							that residential IP-addresses are a digital good and it will be
							delivered in an electronic way (Dashboard). <br />
							<br /> Please read everything before you place an order. <br />
							<br />
							Boiling Proxies can terminate any service without refunding the
							customer if there is a possible damage to the quality, reliability
							or security of Boiling Proxies. <br />
							<br />
							Attempts to commit illegal activity with our Services will result
							in immediate termination of all services. <br />
							<br />
							Boiling Proxies has the right to terminate any customer, without
							refunding or compensating, if there is any suspect that a customer
							participate in illegal activities or breaking the rules/Terms.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Purchase;
