import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./Component/Navbar/Navbar";
import Head from "./Component/Head/Head";
import Feature from "./Component/Feature/Feature";
import Footer from "./Component/Footer/Footer";
import Dashboard from "./Component/Dashboard/Dashboard";
import Plans from "./Component/Plans/Plans";
import Tos from "./Component/Tos/Tos";
import Service from "./Component/Service/Service";
import Purchase from "./Component/Purchase/Purchase";

function App() {
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route exact path="/">
						<Navbar />
						<Head />
						<Feature />
						<Dashboard />
						<Plans />

						<Footer />
					</Route>
					<Route exact path="/tos">
						<Navbar />
						<Tos />
						<Footer />
					</Route>
					<Route exact path="/services">
						<Navbar />
						<Service />
						<Footer />
					</Route>
					<Route exact path="/purchases">
						<Navbar />
						<Purchase />
						<Footer />
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
