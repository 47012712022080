import React from "react";
import img from "../Image/Group 39.png";
import "./Dashboard.css";
const Dashboard = () => {
	return (
		<div className="dashboard">
			<div className="container">
				<div className="row">
					<div
						className="col-10 mx-auto mb-lg-0 mb-5 col-lg-5"
						data-aos="fade-right"
					>
						<div className="dashboard-title mb-3">
							Generate, manage & order <br /> proxies in your own Dashboard.
						</div>
						<div className="dashboard-details">
							<li className="dashboard-list mb-2">
								<span>Generate proxies</span>
							</li>
							<li className="dashboard-list mb-2">
								<span>Manage your orders</span>
							</li>
							<li className="dashboard-list mb-2">
								<span>Track your data usage</span>
							</li>
							<li className="dashboard-list mb-2">
								<span>Top-up your data</span>
							</li>
						</div>
					</div>
					<div className="col-10 mx-auto col-lg-7" data-aos="fade-up">
						<img src={img} alt="" className="img-fluid" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
