import React from "react";
import "./Service.css";
const Service = () => {
	return (
		<div className="service mt-5 mb-5" id="services">
			<div className="container">
				<div className="col-10 col-lg-12 mx-auto">
					<div className="service mb-3" data-aos="fade-up">
						Services
					</div>
					<div className="service-deatils mb-5" data-aos="fade-up">
						Boiling Proxies is a digital product provider that provides its
						users with access to residential IP-addresses that can be used for
						legitimate purposes as protecting their privacy online. Our
						residential IP-addresses will be delivered in forms of internet
						data. Customers can top-up their data by placing an order of data
						per gigabyte. There is no expiry date and it can be used till there
						is nothing left of the purchased data.
					</div>
				</div>
			</div>
		</div>
	);
};

export default Service;
